<template>
  <div class="policySearch">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/policy' }">政策文库</el-breadcrumb-item>
      <el-breadcrumb-item>政策搜索</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="complete">
      <el-autocomplete
        v-model="searchText"
        :fetch-suggestions="querySearch"
        placeholder="请输入政策名称/项目名称"
        style="min-width:666px;"
        clearable @keyup.enter.native="keyupDown"
        @focus="searchFocus"
        ref="cautocomplete"
        @clear="clear"
        :popper-append-to-body="false"
        :debounce="0"
      >
        <template slot-scope="{ item }">
          <div style="display:flex">
            <div
              v-if="searchText"
              class="keyRecord" @click="handleNotice(item, goWhere[0])"
              style="white-space:nowrap;text-overflow: ellipsis;overflow: hidden;flex:1;width:300px"
            >
              {{ item.keyRecord }}
            </div>
            <div
              v-if="!searchText && !item.isHis"
              class="keyRecord"
              style="display: flex;"
              @mouseenter="item.active = true"
              @mouseleave="item.active = false"
            >
              <div
                style="white-space:nowrap;text-overflow: ellipsis;overflow: hidden;flex:1;width:550px"
                @click="handleHistory(item, goWhere[0])"
              >{{ item.keyword }}</div>
              <div
                v-if="item.active"
                @click="delHistory(item, $event)"
                class="keyRecord-icon"
              >
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div
              v-if="!searchText && item.isHis"
              style="width:100%;text-align:center;color:#156ED0"
              @click="handleDelAll"
            >{{item.keyword}}
            </div>
            <!-- <div v-if="!searchText && item.keyword != '清除全部历史'" @click="delHistory(item)"><i
                    class="el-icon-close"></i></div> -->
            <div
              v-if="searchText && !item.isHis"
              class="policy"
              style="margin-left:20px"
              @click="handleNotice(item, goWhere[1])"
            >
              <!-- 申报通知 -->
              申报通知({{ item.noticeCount }})
            </div>
            <div
              v-if="searchText && !item.isHis"
              class="notice"
              style="margin-left:20px"
              @click="handlePolicy(item)"
            >
              <!-- 政策文件 -->
              政策文件({{ item.policyCount }})
            </div>
          </div>
        </template>
        <el-button slot="append" @click="btnSearch">搜索</el-button>
      </el-autocomplete>
    </div>

    <div v-loading="loading">
      <div style="display:flex;margin-top:20px;font-size: 16px;">
        <div
          @click="handleJumpNotice"
          style="height: 41px;"
        >
          <!-- 申报通知 -->
          申报通知&nbsp;&nbsp;({{totalNotice}})
        </div>
        <div
          style="
            margin-left:30px;
            color:#156ED0;
            font-weight: 700;
            border-bottom: 2px solid #156ED0;
            padding-bottom: 10px;
            height: 21px;
          "
        >
          <!-- 政策文件 -->
          政策文件&nbsp;&nbsp;({{totalReg}})
        </div>
      </div>
      <div>
        <div
          v-if="regulationsList.length > 0"
          class="regulations"
          style="margin-top:50px"
          :style="total > 10?'':'padding-bottom:50px'"
        >
          <div
            v-for="(item,index) in regulationsList"
            :key="index"
            :style="index < 1?'border-top:2px solid #D4D6DA;border-bottom:2px solid #D4D6DA':'border-bottom:2px solid #D4D6DA'"
            style="padding:30px 0 38px 0;font-size: 18px;"
            @click="goToFGDetail(item.id)"
          >
            <div style="height:40px;line-height:40px;cursor: pointer;display: flex;">
              <div
                v-html="item.titleCopy"
                style="flex:1;white-space:nowrap;text-overflow: ellipsis;overflow: hidden"
              >
              </div>
              <div style="color: #B2B2B2;">></div>
            </div>
            <div
              style="display:flex;height:20px;line-height:20px;cursor: pointer;font-size: 14px;color: #B8B8B8;">
              <div style="margin-right:30px">{{item.fwDepartment}}</div>
              <div>{{item.dispatchDate}}</div>
            </div>
          </div>
          <el-pagination v-if="total > 10" background layout="prev, pager, next" :total="total"
              :current-page="queryParams.pageNo" @current-change="changeCurrent" style="margin-top:50px">
          </el-pagination>
        </div>
        <div
          v-else
          class="without"
          style="padding-bottom:200px"
        >
          <el-empty description=""></el-empty>
          <div
            style="text-align:center"
          >
            <span style="color:rgba(0,0,0,.6)">没有找到关于</span>
            <span style="color:#156ED0">
              "{{noRegTitle}}"
            </span>
            <span style="color:rgba(0,0,0,.6)">的政策文件</span>
          </div>
        </div>
          </div>
      </div>
  </div>
</template>

<script>

import { request } from '@/network';
import {
  // setInfoData,
  getMemberId
} from '@/assets/public/utils/token';
import { getHeighLight } from '@/utils/heightLight';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      pageNo: 1,
      total: 0,
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        sortType: 0,
        city: '',
        keyWord: '',
        memberId: getMemberId()
      },
      regulationsList: [],
      searchText: '',
      str: '',
      loading: false,
      totalNotice: 0,
      totalReg: 0,
      noRegTitle: '',
      totalNoticeCopy: 0,
      goWhere: [
        'current', // 保留当前页
        'notice', // 跳转到申报通知
        'policy' // 跳转到法规
      ],
      isFirst: true
    };
  },
  mounted () {
    this.searchText = this.$route.query.keyWord;
    this.queryParams.keyWord = this.$route.query.keyWord;
    this.str = this.$route.query.keyWord;
    this.totalNotice = this.$route.query.notice;
    this.totalReg = this.$route.query.policy;
    // if(this.$route.query.previous){
    //     if(this.totalReg==0&&this.totalNotice!=0){
    //         this.$router.push({ path: '/policySearchNotice', query: { keyWord: this.searchText, notice: this.totalNotice, policy: this.totalReg,type:1} })
    //     }
    // }
    this.getList();
    this.getSearchCount();
  },
  computed: {
    ...mapState({
      selectCity: (state) => state.menu.selectCity
    })
  },
  watch: {
    selectCity: {
      handler (val) {
        if (!this.isFirst) {
          this.queryParams.pageNo = 1;
          this.getList(val);
          this.getSearchCount();
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取政策文件
    async getList (val) {
      this.loading = true;
      this.queryParams.city = val || localStorage.getItem('selectCity') || '广州市';
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/policylibrary/getRule',
        data: this.queryParams
      });
      const isDetailBack = JSON.parse(localStorage.getItem('isDetailBack')); // 如果是detail页面跳转回来的话就不回到顶部
      if (!this.isFirst && !isDetailBack) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      localStorage.setItem('isDetailBack', false);
      this.isFirst = false;
      // 修改搜索词的时候不刷新地改变url
      if (this.$route.query.keyWord !== this.queryParams.keyWord) {
        let arr = window.location.search.split('&');
        arr[0] = '?keyWord=' + this.queryParams.keyWord;
        this.$router.push(this.$route.path + arr.join('&'));
      }
      if (res.code !== 200) return this.$message.error('数据出错，请刷新');
      // 文字高亮
      const { policyList, totalSize } = res.data;
      const str = this.searchText;
      policyList.forEach(item => {
        item.titleCopy = str ? getHeighLight(str, item.title) : item.title;
        // const regSplit = item.title.split(str)
        // item.titleCopy = ''
        // regSplit.forEach((v, i) => {
        //   if (i < regSplit.length - 1) {
        //     item.titleCopy += v + '<font color="#156ED0">' + str + '</font>'
        //   } else {
        //     item.titleCopy += v
        //   }
        // })
      });
      this.regulationsList = policyList;
      this.total = totalSize;
      this.noRegTitle = this.queryParams.keyWord;
      this.loading = false;
    },
    async getSearchCount () {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/librarysearch/getSearchCount',
        data: {
          city: localStorage.getItem('selectCity'),
          keyWord: this.queryParams.keyWord
        }
      });
      if (res.code !== 200) return this.$message.error('数据出错，请刷新');
      this.totalNotice = res.data.notice;
      this.totalReg = res.data.policy;
    },
    querySearch (queryString, cb) {
      if (queryString) {
        request({
          method: 'POST',
          url: '/pcp/librarysearch/getSearchHistory',
          data: {
            keyWord: queryString,
            city: localStorage.getItem('selectCity'),
            memberId: getMemberId()
          }
        }).then(res => {
          if (res.data.code !== 200) return this.$message.error('暂无数据');
          const result = res.data.data;
          result.map((item, index) => {
            item.value = item.keyRecord;
          });
          cb(result);
        });
      } else {
        request({
          method: 'GET',
          url: '/pcp/librarysearch/getMemberSearchHistory',
          params: {
            memberId: getMemberId()
          }
        }).then(response => {
          if (response.data.code !== 200) return this.$message.error('暂无数据');
          let result = [];
          const arr = [ { keyword: '清除全部历史', isHis: true } ];
          response.data.data.map((item, index) => {
            item.value = item.keyword;
            item.active = false;
          });
          result = response.data.data.length > 0 ? response.data.data.concat(arr) : response.data.data;
          this.historyList = response.data.data;
          cb(result);
        });
      }
    },
    goToNotice (query) {
      this.$router.push({
        path: '/policySearchNotice',
        query: query
      });
    },
    handleNotice (item, type) {
      const { sortType, uuidList, isEnd } = this.$route.query;
      request({
        method: 'POST',
        url: '/pcp/librarysearch/getSearchCount',
        data: {
          city: localStorage.getItem('selectCity'),
          keyWord: item.keyRecord
        }
      }).then(res => {
        if (res.data.code === 200) {
          if (type === 'notice') {
            const { notice, policy } = res.data.data;
            const query = {
              keyWord: item.keyRecord,
              notice: notice,
              policy: policy,
              sortType: sortType,
              uuidList: uuidList,
              isEnd: isEnd
            };
            this.goToNotice(query);
          } else {
            this.str = item.keyRecord;
            this.queryParams.keyWord = item.keyRecord;
            this.queryParams.pageNo = 1;
            this.getList().then(() => {
              this.getSearchCount()
            });
          }
        }
      });
    },
    // 点击历史记录
    handleHistory (item) {
      this.str = item.keyword;
      this.queryParams.keyWord = item.keyword;
      this.queryParams.pageNo = 1;
      this.getList().then(() => {
        this.getSearchCount()
      });
      // const { sortType, uuidList, isEnd } = this.$route.query
      // request({
      //   method: 'POST',
      //   url: '/pcp/librarysearch/getSearchCount',
      //   data: {
      //     city: localStorage.getItem('selectCity'),
      //     keyWord: item.keyword
      //   }
      // }).then(res => {
      //   if (res.data.code === 200) {
      //     const { notice, policy } = res.data.data
      //     const query = {
      //       keyWord: item.keyword,
      //       notice: notice,
      //       policy: policy,
      //       sortType: sortType,
      //       uuidList: uuidList,
      //       isEnd: isEnd,
      //       type: 2
      //     }
      //     this.goToNotice(query)
      //   }
      // })
    },
    handlePolicy (item) {
      this.str = item.keyRecord;
      this.queryParams.keyWord = item.keyRecord;
      this.queryParams.pageNo = 1;
      this.getList().then(() => {
        this.getSearchCount()
      });
    },
    searchFocus () {
      const selectBox = document.getElementsByClassName('el-autocomplete-suggestion')[0];
      selectBox.style.removeProperty('display');
    },
    btnSearch () {
      const selectBox = document.getElementsByClassName('el-autocomplete-suggestion')[0];
      selectBox.style.display = 'none';
      this.str = this.searchText;
      this.queryParams.keyWord = this.searchText;
      this.queryParams.pageNo = 1;
      this.getList().then(() => {
        this.getSearchCount();
      });
    },
    // 删除历史记录
    delHistory (item, event) {
      event = event || window.event;
      event.cancelBubble = true;
      const dom = this.$refs.cautocomplete;
      const arr = [];
      arr.push(item.id);
      request({
        method: 'POST',
        url: '/pcp/librarysearch/deleteMemberSearchHistory',
        data: {
          ids: arr
        }
      }).then(res => {
        if (res.data.code !== 200) return this.$message.error('删除失败');
        this.$message.success('删除成功');
        // this.searchText = ''
        dom.focus();
      });
    },
    // 删除全部历史记录
    handleDelAll () {
      const arr = [];
      this.historyList.forEach(v => {
        arr.push(v.id);
      });
      request({
        method: 'POST',
        url: '/pcp/librarysearch/deleteMemberSearchHistory',
        data: {
          ids: arr
        }
      }).then(res => {
        if (res.data.code !== 200) return this.$message.error('删除失败');
        this.$message.success('删除成功');
      });
    },

    changeCurrent (val) {
      this.queryParams.pageNo = val;
      this.getList();
    },

    goToFGDetail (id) {
      this.$router.push({
        path: '/faguidetails',
        query: {
          id: id
        }
      });
    },
    handleJumpNotice () {
      const { sortType, uuidList, isEnd, supportType, declareType } = this.$route.query;
      let notice = 0;
      notice = this.totalNoticeCopy ? this.totalNoticeCopy : this.totalNotice;
      const query = {
        keyWord: this.searchText,
        notice: notice,
        policy: this.totalReg,
        sortType: sortType,
        uuidList: uuidList,
        isEnd: isEnd,
        supportType: supportType,
        declareType: declareType,
        type: 2
      };
      this.goToNotice(query);
    },
    keyupDown () {
      this.str = this.searchText;
      this.queryParams.keyWord = this.searchText;
      this.queryParams.pageNo = 1;
      // 取消聚焦
      document.activeElement.blur();
      // 隐藏下拉框
      this.$refs.cautocomplete.suggestions = [];
      this.getList().then(() => {
        this.getSearchCount()
      });
    },
    // 清除
    clear () {
      this.$refs.cautocomplete.activated = true;
      this.str = '';
      this.queryParams.pageNo = 1;
      this.queryParams.keyWord = '';
      // this.getList().then(() => {
      //   this.getSearchCount().then(() => {
      //     this.totalNotice = this.totalNoticeCopy
      //   })
      // })
    }
  }
};
</script>

<style lang="less" scoped>
.policySearch {
    background-color: #fff;
    padding: 43px 0px 0 0px;

    .search {
        margin-top: 30px;
    }
}

.complete {
    margin-top: 30px;
}

/deep/.el-tabs__nav-wrap::after {
    display: none !important;
}

/deep/.el-tabs__active-bar {
    background-color: #156ED0;
}

/deep/.el-tabs__item.is-active {
    color: #156ED0;
}

/deep/.el-input__inner {
    height: 52px;
    line-height: 52px;
}

/deep/.el-input-group__append {
    background-color: #156ED0;
    color: #fff;
    border: 1px solid #156ED0
}

.formalFile-item {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    margin: 5px 0px 5px 0px;
    padding-left: 15px;
    color: #585858;
    box-sizing: border-box;
    cursor: pointer;
    background: rgb(248, 248, 248);
    border: 1px solid rgb(231, 231, 231);
    display: flex;
    align-items: center;

    .subsidies {
        border: 1px solid #ffa142;
        color: #ffa142 !important;
        font-size: 14px;
        border-radius: 3px;
        line-height: 20px;
        height: 20px;
        line-height: 20px;
        padding: 3px 5px 3px 5px;
    }
}

.red-title {
    color: red;
    font-size: 14px;
}

.gray-title {
    color: gray;
    font-size: 14px;
}

.type-1 {
    background: #fb9435;
}

.type-2 {
    background: #1f88f9;
}

.type-3 {
    background: #BF382F;
}

.type-4 {
    background: #2EBC66;
}

.type-5 {
    background: #6cc2ed;
}

.fuchifangshi {
    font-size: 14px;
    border-radius: 3px;
    line-height: 20px;
    height: 20px;
    line-height: 20px;
    padding: 3px 5px 3px 5px;
    color: #ffffff;
    margin-right: 15px;
}

.el-pagination {
    text-align: center;
    padding-bottom: 50px;
}

.screening {
    display: flex;
    font-size: 14px;
    margin-bottom: 50px;
    margin-top: 10px;
    align-items: center;

    &>div {
        margin-right: 50px;

        /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background-color: #fff;
            color: #156ED0;
            border-color: #156ED0;
        }

        /deep/.el-radio-button__inner:hover {
            color: #156ED0;
        }
    }
}
.keyRecord {
  width: 100%;
  padding: 0 20px;
}
.keyRecord:hover {
    color: #156ED0
}
.notice {
  margin-right: 20px;
}
.policy:hover {
    color: #156ED0
}

.notice:hover {
    color: #156ED0
}

/deep/.el-empty__description p {
    display: none;
}

/deep/.el-empty {
    padding: 0;
}
/deep/.el-autocomplete-suggestion li {
  padding: 0 !important;
}
</style>
