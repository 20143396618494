function changeTitleColor (str, title) {
  const strArr = [];
  for (const ele of title) {
    if (str.includes(ele)) {
      const obj = {
        text: ele,
        show: true
      };
      strArr.push(obj);
    } else {
      const obj = {
        text: ele,
        show: false
      };
      strArr.push(obj);
    }
  }
  return strArr;
}
function handleTitleColor (str, title) {
  const textArr = changeTitleColor(str, title);
  textArr.forEach((item, index) => {
    if (item.show && str.length > 1) {
      if (index >= 1 && index < textArr.length - 1) {
        if (!textArr[index - 1].show && !textArr[index + 1].show) { // 判断该字体前后是否有高亮的字 至少有两个才可以高亮，除非搜索字只有一个
          item.show = false;
        }
      } else if (index < 1) {
        if (!textArr[index + 1].show) {
          item.show = false;
        }
      } else if (index >= textArr.length - 1) {
        if (!textArr[index - 1].show) {
          item.show = false;
        }
      }
    }
  });
  return textArr;
}
export function getHeighLight (str, title) {
  const textArr = handleTitleColor(str, title);
  const strArr = [];
  textArr.forEach(item => {
    if (item.show) {
      strArr.push(`<font style="color: #156ED0;">${item.text}</font>`);
    } else {
      strArr.push(item.text);
    }
  });
  return strArr.join('');
}
